/**
 * Generates an array of consecutive numbers from 0 to maxRailSize - 1,
 * shuffles the array elements, and returns the shuffled array using
 * the Fisher-Yates shuffle algorithm.
 *
 * @param {number} maxRailSize - The upper limit (exclusive) of the range of numbers.
 * @returns {number[]} The array containing shuffled numbers from 0 to maxRailSize - 1.
 */
export function initRandomIndexArray(maxRailSize = 100) {
  const result = []

  for (let i = 0; i < maxRailSize; i++)
    result.push(i)

  for (let i = maxRailSize - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]]
  }

  return result
}
